<template>
  <div
    class="PSInput"
    :class="[
      size ? `PSInput--size-${size}` : '',
      `PSInput--mode-${mode}`,
      {
        'PSInput--required': !label && required,
        'PSInput--readonly': readonly,
        'PSInput--disabled': disabled,
        'PSInput--rounded': isRounded,
      },
    ]"
    :style="[
      {
        width: width,

        '--widthDropdown': widthDropdown,
      },
    ]"
  >
    <div
      v-if="label"
      class="PSInput__label"
      :class="{
        'PSInput__label--required': required,
      }"
    >
      {{ label }}
    </div>

    <tippy
      interactive
      :arrow="false"
      :offset="[0, 7]"
      :max-width="widthDropdown"
      :trigger="disabled || readonly ? '' : 'click'"
      :placement="'bottom'"
      :on-show="onShow"
      :on-hide="onHide"
      :theme="'dropdown'"
    >
      <!-- TEXTAREA -->
      <template v-if="type === EInputTypes.Textarea">
        <textarea
          ref="inputElement"
          :readonly="readonly"
          class="PSInput__textarea"
          :class="[
            theme ? `PSInput--theme-${theme}` : '',
            _noTransition ? `PSInput--no-transition` : '',
          ]"
          :style="{
            '--height': heightResult,
            '--max-height': maxHeight,
            // height: getHeight,
            width: width,
          }"
          :placeholder="placeholder"
          :value="modelValueFormat"
          @input="onInput"
          @change="(event) => onChange(event.target.value)"
        />
        <!-- второй скрытый для автовысоты, он моментально меняет высоту, и дает значение новой высоты, которую анимируем на основное поле -->
        <textarea
          v-if="autoHeight"
          ref="inputElement2"
          tabindex="-1"
          class="PSInput__textarea PSInput__textarea--hidden"
          :style="{
            width: calcWidthForHiddenTextarea,
          }"
          :placeholder="placeholder"
          :value="modelValueFormat"
        />
      </template>

      <!-- TIME -->
      <template v-else-if="type === EInputTypes.Time">
        <PSSelect
          :readonly="readonly"
          :model-value="modelValueFormat"
          :options="getTimeValues()"
          placeholder="..."
          align-center
          hide-arrow
          width="90px"
          @update:model-value="emit('update:modelValue', $event)"
        />
      </template>

      <!-- PHONE -->
      <MaskInput
        v-else-if="type === EInputTypes.Tel"
        ref="inputElement"
        :readonly="readonly"
        :autocomplete="autocompleteOff ? 'off' : 'on'"
        mask="+7 ### - ### ## ##"
        class="PSInput__input"
        :class="[theme ? `PSInput--theme-${theme}` : '']"
        :type="type"
        :value="modelValueFormat"
        :placeholder="placeholder"
        :style="{ width: width }"
        @change="(event) => onChange(event.target.value)"
        @input="onInput"
        @keypress="preventNumericInput($event)"
      />

      <!-- TEXT -->
      <input
        v-else
        ref="inputElement"
        :readonly="readonly"
        :autocomplete="autocompleteOff ? 'off' : 'on'"
        class="PSInput__input"
        :class="[
          align ? `text-${align}` : '',
          theme ? `PSInput--theme-${theme}` : '',
          {
            'PSInput--price': format === 'price',
            'PSInput--number': format === 'number',
          },
        ]"
        :type="type"
        :value="modelValueFormat"
        :placeholder="placeholder"
        :style="{ width: width }"
        @change="(event) => onChange(event.target.value)"
        @input="onInput"
        @keypress="preventNumericInput($event)"
        @focus="onFocus"
        @blur="onBlur"
      >

      <!-- Выпадающий список -->
      <template
        v-if="props.hasDropdown"
        #content="{ hide }"
      >
        <div
          class="PSSelect__dropdown"
          :style="{ '--widthDropdown': widthDropdown }"
        >
          <div
            ref="scroll"
            class="PSSelect__scroll"
          >
            <template v-if="multiple && multipleMap">
              <div
                v-if="props.resetLabel"
                ref="scroll2"
                class="PSSelect__item"
                :class="{ active: multipleMap.length === 0 }"
                @click="reset"
              >
                {{ props.resetLabel }}
              </div>
              <div
                v-for="(option, optionIndex) in optionsSearch?.length
                  ? optionsSearch
                  : options"
                :key="option.value"
                ref="scroll2"
                class="PSSelect__item"
                :class="{ active: multipleMap[optionIndex] }"
              >
                <PSCheck
                  v-model="multipleMap[optionIndex]"
                  :label="option.label"
                />
              </div>
            </template>
            <template v-else>
              <div
                v-if="
                  props.resetLabel &&
                    modelValue !== undefined &&
                    modelValue !== ''
                "
                ref="scroll2"
                class="PSSelect__item"
                @click="reset"
              >
                {{ props.resetLabel }}
              </div>

              <div
                v-for="option in props.search ? optionsSearch : options"
                :key="option.value"
                ref="scroll2"
                class="PSSelect__item"
                :class="{ active: modelValue === option.value }"
                @click="
                  setActive(option.value);
                  hide();
                "
              >
                {{ option.label }}
              </div>
            </template>
          </div>
        </div>
      </template>
    </tippy>
  </div>
</template>

<script setup lang="ts">
import { MaskInput, } from 'vue-3-mask';
import { EInputTypes, EInputMode, } from './PSInput.enum';
import { IInputProps, } from './PSInput.type';
import PSSelect from './PSSelect.vue';
import unFormatPrice from '@/utils/unFormatPrice';
import { getTimeValues, } from '@/utils/timeValues';

const inputElement = ref<HTMLElement>();
const inputElement2 = ref<HTMLElement>();

const props = withDefaults(defineProps<IInputProps>(), {
  type: EInputTypes.Text,
  mode: EInputMode.Default,
});

const _noTransition = ref(true);

onMounted(async () => {
  await nextTick();
  _noTransition.value = props.noTransition;
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void;
  (e: 'input', value: string | number): void;
  (
    e: 'update:modelValueOption',
    value: string | number | Array<string | number> | undefined
  ): void;
  (e: 'update:search', value: string | number): void;
}>();

const calcWidthForHiddenTextarea = computed(() => {
  if (props.autoHeight && inputElement.value) {
    return inputElement.value.clientWidth + 'px';
  } else {
    return 0 + 'px';
  }
});

const heightResult = ref('0px');
const calcHeight = () => {
  if (props.autoHeight) {
    if (!inputElement.value) {
      props.height || '120px';
    }

    setTimeout(() => {
      if (
        inputElement.value &&
        inputElement2.value &&
        inputElement2.value?.scrollHeight
      ) {
        const propHeightNumber = Number(props.height?.replaceAll('px', ''));

        if (!propHeightNumber) {
          heightResult.value = props.height || '120px';
        } else if (inputElement2.value.scrollHeight > propHeightNumber) {
          heightResult.value = inputElement2.value.scrollHeight + 'px';
        } else {
          heightResult.value = props.height || '120px';
        }
      }
    });
  } else {
    if (props.height) {
      heightResult.value = props.height;
    }
  }
};

onMounted(() => {
  calcHeight();
});

const isFocus = ref(false);

const modelValueFormat = computed(() => {
  if (
    isFocus.value &&
    !props.readonly &&
    !props.disabled &&
    unFormatPrice(props.modelValue) === 0
  ) {
    return '';
  }

  if (props.format === 'price') {
    return unFormatPrice(props.modelValue).toLocaleString('ru', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else if (props.format === 'number') {
    return unFormatPrice(props.modelValue).toLocaleString('ru');
  } else {
    return props.modelValue;
  }
});

const preventNumericInput = ($event) => {
  if (props.format === 'price' || props.format === 'number') {
    var keyCode = $event.keyCode ? $event.keyCode : $event.which;
    console.log('🚀 ~ preventNumericInput ~ keyCode:', keyCode);

    /**
     * 48-57 - цифры
     * 44 - запятая
     * 46 - точка
     */
    if (!((keyCode > 47 && keyCode < 58) || keyCode === 44 || keyCode === 46)) {
      $event.preventDefault();
    }
  }
};

function formatValue(value) {
  if (props.format === 'price') {
    emit('update:modelValue', Number(unFormatPrice(value)).toFixed(2));
  } else if (props.format === 'number') {
    emit('update:modelValue', Number(unFormatPrice(value)));
  } else {
    emit('update:modelValue', value);
  }
}

const onChange = (value) => {
  formatValue(value);

  if (props.autoHeight) {
    calcHeight();
  }
};
const onInput = (event) => {
  emit('input', event.target.value);

  if (props.autoHeight) {
    calcHeight();
  }
};

function onFocus() {
  isFocus.value = true;
}

function onBlur() {
  isFocus.value = false;
}

/* Код для выпадашки */
const isShow = ref(false);
const scroll = ref();
const scroll2 = ref();
const widthDropdown = ref('');

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (value !== undefined) {
      emit('update:modelValueOption', value === null ? undefined : value);
    }
  },
});

const getLabelActive = computed(() => {
  if (!props.options || !props.options.length) {
    return '';
  }

  if (props.multiple) {
    let activeLabels = '';

    // Собираем строку из выделенных значений
    if (typeof value.value === 'object') {
      value.value?.forEach((t) => {
        if (props.options) {
          const option = props.options.filter((tt) => t === tt.value)[0];
          if (activeLabels !== '') {
            activeLabels += ',';
          }
          activeLabels += ' ' + option.label;
        }
      });

      return { label: activeLabels, value: value.value, };
    }
  } else {
    return props.options.filter((t) => value.value === t.value)[0];
  }
});

const setActive = (newValue) => {
  console.log('🔷 ~ file: PSSelect.vue:235 ~ setActive ~ newValue:', newValue);
  value.value = newValue;
};

const multipleMap = ref(props.options?.map((t) => false));

if (props.multiple) {
  watchEffect(() => {
    const values = multipleMap.value?.reduce((acc, item, index) => {
      if (item) {
        if (props.options) {
          acc.push(props.options[index].value);
        }
      }
      return acc;
    }, [] as Array<string | number>);

    emit('update:modelValueOption', values);
  });
}

watchEffect(() => {
  if (props.dropdownAutoWidth) {
    widthDropdown.value = 'auto';
  } else if (inputElement.value && inputElement.value.clientWidth) {
    widthDropdown.value = inputElement.value.clientWidth + 'px';
  }
});

const onShow = async (tippyWrap) => {
  console.log('onShow');

  if (!(props.disabled || props.readonly)) {
    isShow.value = true;
    await nextTick();

    // здесь автоподкрутка скролла к активному элементу
    let activeIndex = 0;

    if (scroll2.value && scroll2.value[0]) {
      const h = scroll2.value[0].clientHeight;

      scroll2.value.forEach((item, ndx) => {
        if (item.classList.contains('active')) {
          activeIndex = ndx;
        }
      });

      scroll.value.scrollTop = activeIndex * h;
    }
  }
};

const onHide = (instance) => {
  console.log('onHide');
  instance.value?.hide();
  isShow.value = false;
};

const reset = () => {
  if (props.multiple) {
    emit('update:modelValueOption', []);
    if (multipleMap.value) {
      multipleMap.value = props.options?.map((t) => false);
    }
  } else {
    emit('update:modelValueOption', undefined);
  }
};

if (props.tooltip) {
  useTippy(inputElement, {
    content: props.tooltip,
  });
}
</script>

<style lang="scss" scoped>
.PSInput {
  $s: &;
  line-height: 0;
  position: relative;

  &--required {
    &::before {
      position: absolute;
      content: '*';
      width: 4px;
      height: 4px;
      left: -10px;
      top: 10px;
      color: $red;
    }
  }
  &__label {
    color: #464646;
    font-weight: 300;
    margin-bottom: 10px;
    padding-left: 10px;
    line-height: 1.25;
    position: relative;
    &--required {
      &::before {
        position: absolute;
        content: '*';
        left: 0px;
        top: 0;
        color: $red;
      }
    }
  }
  &__textarea,
  &__input {
    font-family: Roboto;
    width: 100%;
    font-size: 20px;
    background-color: $light-max;
    padding: 7px 15px;
    border: calc(1px / var(--zoom)) solid transparent;
    border-radius: 6px;
    outline: none;
    caret-color: black;

    &:focus {
      border-color: $blue;
    }
    &:placeholder {
      color: #464646;
    }
  }
  &__textarea {
    color: black;
    height: var(--height);
    max-height: var(--max-height);
    transition: 0.3s;
    border: none !important;
    outline: none;
    resize: none;
    @include scroll;

    &--hidden {
      --height: 0px !important;
      transition: 0s;
      position: absolute !important;
      left: -999px;
      top: -999px;
      z-index: 8999;
      pointer-events: none;
      transform: translate(-9999px, -9999px);
      opacity: 0;
      @include scroll;
    }
  }
  &__input {
    height: 40px;
  }
  &--no-transition {
    transition: 0s !important;
  }
  &--price {
    text-align: right;
  }
  &--theme {
    &-clear {
      padding: 0;
      border: none;
      background: none;
    }
    &-white {
      border: 2px solid #c2c2c2;
      background-color: white;
      transition: $tr;

      &:focus,
      &:hover {
        border-color: $blue;
      }
    }
    &-bordered {
      border: 2px solid #c2c2c2;
      background-color: transparent;
      transition: $tr;

      &:focus,
      &:hover {
        border-color: $blue;
      }
    }
  }

  &--readonly {
    #{$s}__input {
      border-color: transparent !important;
    }
  }

  &--disabled {
    opacity: 0.6;
    #{$s}__input {
      border-color: transparent !important;
    }
  }

  &--size {
    &-big {
      .PSInput__input {
        font-size: 28px;
      }
    }
    &-lg {
      .PSInput__input {
        min-height: 56px;
        font-size: 24px;
      }
    }
  }

  &--mode {
    &-default {
    }
    &-inline {
      display: flex;
      grid-gap: 20px;
      .PSInput__label {
        width: 40%;
      }
      .PSInput__input {
        width: 60%;
      }
    }
  }

  &--rounded {
    input {
      border-radius: 20px;
    }
  }
}
.PSSelect {
  $s: &;

  position: relative;
  width: var(--width);

  &__label {
    color: #464646;
    font-weight: 300;
    margin-bottom: 10px;
    padding-left: 10px;
    line-height: 1.25;

    &--required {
      &::before {
        position: absolute;
        content: '*';
        left: 0px;
        top: 0;
        color: $red;
      }
    }
  }
  &__color-marker {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: var(--bg-color);
  }
  &__select {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: var(--width);
    height: var(--height);

    &._disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    &._readonly {
    }
    &._hide-arrow {
      #{$s}__value {
        padding-right: 15px;
      }
    }
    &._show-border {
      #{$s}__value {
        border-color: $blue;
      }
    }
    &._align-center {
      text-align: center;
    }
  }
  &__input {
    border: none !important;
    outline: none !important;
    background-color: $light-max;
    opacity: 0;
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    padding: 10px - 2px 16px - 2px;
    font-size: 20px;

    &:focus {
      opacity: 1;
    }
  }
  &__value {
    font-size: 20px;
    line-height: 1;
    background-color: $light-max;
    min-height: 40px;
    height: var(--height);
    padding: 9px 15px;
    padding-right: 34px;
    border: 1px solid transparent;
    border-radius: 6px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &-row {
      display: flex;
      align-items: center;
    }

    &-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__placeholder {
    color: #464646;
    font-weight: 300;
  }
  &__dropdown {
    padding: 10px 0;
    max-width: 100%;
    width: var(--widthDropdown);
  }
  &__scroll {
    max-height: 200px;
    overflow: auto;

    padding: 0 16px;

    @include scroll;
  }
  &__arrow {
    position: absolute;
    right: 16px;
    // top: 8px;
    top: 50%;

    transform: translate(0, -50%) rotate(90deg);
    :deep(svg) {
      fill: $blue;
      width: 6px;
      height: 11px;
    }
  }
  &__item {
    font-size: 20px;
    cursor: pointer;
    position: relative;
    z-index: 1;

    @include hover {
      color: $blue;
    }
    &.active {
      color: white;
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: $blue;
        left: -16px;
        right: -16px;
        top: 0;
        bottom: 0;
      }
    }
  }
}
</style>
