import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  useRouter,
} from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
    component: () => import('@/pages/MainPage.vue'),

    children: [
      // dashboard
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/pages/main/DashboardPage.vue'),
      },
      // news
      {
        path: 'news',
        name: 'news',
        component: () => import('@/pages/main/NewsPage.vue'),
      },
      // docs
      {
        path: 'docs',
        name: 'docs',
        component: () => import('@/pages/main/DocsPage.vue'),
      },
      // report-salary
      {
        path: 'report-salary',
        name: 'report-salary',
        component: () => import('@/pages/main/ReportSalaryPage.vue'),
      },
      // report-parts
      {
        path: 'report-parts',
        name: 'report-parts',
        component: () => import('@/pages/main/ReportPartsPage.vue'),
      },
      // moving
      {
        path: 'moving',
        name: 'moving',
        component: () => import('@/pages/main/MovingPage.vue'),
      },
      // profit-loss
      {
        path: 'profit-loss',
        name: 'profit-loss',
        component: () => import('@/pages/main/ProfitLossPage.vue'),
      },
      // balance
      {
        path: 'balance',
        name: 'balance',
        component: () => import('@/pages/main/BalancePage.vue'),
      },
      // upload-1c
      {
        path: 'upload-1c',
        name: 'upload-1c',
        component: () => import('@/pages/main/Upload1cPage.vue'),
      },
    ],
  },

  // Ui
  {
    path: '/ui',
    name: 'ui',
    component: () => import('@/pages/UIPage.vue'),
  },
  // Planner
  {
    path: '/planner',
    name: 'planner',
    component: () => import('@/pages/PlannerPage.vue'),
  },
  // Orders
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/pages/OrdersPage.vue'),
    redirect: '/orders/list',

    children: [
      // list
      {
        path: '/orders/list',
        name: 'orders-list',
        component: () => import('@/pages/orders/OrdersListPage.vue'),
      },
      // kanban
      {
        path: '/orders/kanban',
        name: 'orders-kanban',
        component: () => import('@/pages/orders/OrdersKanbanPage.vue'),
      },
      // favorite
      {
        path: '/orders/favorite',
        name: 'orders-favorite',
        component: () => import('@/pages/orders/OrdersListPage.vue'),
      },
      // archived
      {
        path: '/orders/archived',
        name: 'orders-archived',
        component: () => import('@/pages/orders/OrdersListPage.vue'),
      },
      // order-acts
      {
        path: '/orders/order-acts',
        name: 'orders-order-acts',
        component: () => import('@/pages/orders/DevelopPage.vue'),
      },
      // order-assessments
      {
        path: '/orders/order-assessments',
        name: 'orders-order-assessments',
        component: () => import('@/pages/orders/DevelopPage.vue'),
      },
      // transfer-shift
      {
        path: '/orders/transfer-shift',
        name: 'orders-transfer-shift',
        component: () => import('@/pages/orders/OrdersTransferShiftPage.vue'),
      },
    ],
  },
  // Clients
  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/pages/ClientsPage.vue'),
    redirect: '/clients/all',
    children: [
      // auto-in-service
      {
        path: '/clients/auto-in-service',
        name: 'auto-in-service',
        component: () => import('@/pages/orders/DevelopPage.vue'),
      },
      // :type
      {
        path: ':type',
        component: () => import('@/pages/ClientsPage.vue'),
      },
    ],
  },
  // Users
  {
    path: '/users',
    name: 'users',
    component: () => import('@/pages/UserPage.vue'),
    redirect: '/users/all',
    children: [
      // :type
      {
        path: ':type',
        component: () => import('@/pages/UserPage.vue'),
        children: [
          // :post
          {
            path: ':post',
            component: () => import('@/pages/UserPage.vue'),
          },
        ],
      },
    ],
  },
  // Settings
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/pages/SettingsPage.vue'),
    redirect: '/settings/company',
    children: [
      // /settings/company
      {
        path: '/settings/company',
        name: 'company',
        component: () => import('@/pages/settings/SettingsCompany.vue'),
      },
      // /settings/locations
      {
        path: '/settings/locations',
        name: 'locations',
        component: () => import('@/pages/settings/SettingsLocations.vue'),
      },
      // /settings/registers
      {
        path: '/settings/registers',
        name: 'registers',
        component: () => import('@/pages/settings/SettingsRegisters.vue'),
      },
      // /settings/price-list
      {
        path: '/settings/price-list',
        name: 'price-list',
        component: () => import('@/pages/settings/SettingsPriceList.vue'),
      },
      // /settings/sales
      {
        path: '/settings/sales',
        name: 'sales',
        component: () => import('@/pages/settings/SettingsPriceList.vue'),
      },
      // /settings/dds
      {
        path: '/settings/dds',
        name: 'dds',
        component: () => import('@/pages/settings/SettingsPriceList.vue'),
      },
      // /settings/integration
      {
        path: '/settings/integration',
        name: 'integration',
        component: () => import('@/pages/settings/SettingsPriceList.vue'),
      },
      // /settings/license
      {
        path: '/settings/license',
        name: 'license',
        component: () => import('@/pages/settings/SettingsPriceList.vue'),
      },
      // /settings/updates
      {
        path: '/settings/updates',
        name: 'updates',
        component: () => import('@/pages/settings/SettingsUpdates.vue'),
      },
    ],
  },
  // Parts
  {
    path: '/parts',
    name: 'parts',
    component: () => import('@/pages/PartsPage.vue'),
    redirect: '/parts/warehouse',
    children: [
      // warehouse
      {
        path: '/parts/warehouse',
        name: 'parts-warehouse',
        component: () => import('@/pages/parts/PartsWarehousePage.vue'),
      },
      // remains
      {
        path: '/parts/remains',
        name: 'parts-remains',
        component: () => import('@/pages/parts/PartsWarehousePage.vue'),
        // component: () => myComponentFactory('remains', PartsWarehousePage),
        meta: {
          filter: {
            hasMinQuantity: true,
          },
        },
      },
      {
        path: '/parts/favorite',
        name: 'parts-favorite',
        component: () => import('@/pages/parts/PartsWarehousePage.vue'),
        // component: () => myComponentFactory('favorite', PartsWarehousePage),
        meta: {
          filter: {
            isFavorite: true,
          },
        },
      },
      {
        path: '/parts/archived',
        name: 'parts-archived',
        component: () => import('@/pages/parts/PartsWarehousePage.vue'),
        // component: () => myComponentFactory('archived', PartsWarehousePage),
        meta: {
          filter: {
            isArchive: true,
          },
        },
      },
      {
        path: '/parts/orders',
        name: 'parts-orders',
        component: () => import('@/pages/parts/PartsIncomesPage.vue'),
        meta: {
          options: {
            isOrders: true,
          },
        },
      },
      {
        path: '/parts/incomes',
        name: 'parts-incomes',
        component: () => import('@/pages/parts/PartsIncomesPage.vue'),
      },
      {
        path: '/parts/reserve',
        name: 'parts-reserve',
        component: () => import('@/pages/parts/PartsReservePage.vue'),
      },
    ],
  },
  // Money
  {
    path: '/money',
    name: 'money',
    component: () => import('@/pages/MoneyPage.vue'),
    redirect: '/money/operations',
    children: [
      // operations
      {
        path: '/money/operations',
        name: 'operations',
        component: () => import('@/pages/money/MoneyOperations.vue'),
      },
      // movements
      {
        path: '/money/movements',
        name: 'movements',
        component: () => import('@/pages/money/MoneyMovements.vue'),
      },
      // accounting
      {
        path: '/money/accounting',
        name: 'accounting',
        component: () => import('@/pages/money/MoneyAccounting.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

NProgress.configure({
  showSpinner: false,
});

router.beforeEach(() => {
  NProgress.start();
});

router.afterEach(() => {
  NProgress.done();
});
export default router;
