<template>
  <div
    ref="select"
    class="PSSelect"
    :style="{
      '--width': width,
      '--height': height,
      '--widthDropdown': widthDropdown,
    }"
  >
    <!-- {{ multipleMap }}
    {{ value }}
    {{ getLabelActive }} -->
    <div
      v-if="label"
      class="PSSelect__label"
      :class="{
        'PSSelect__label--required': required,
      }"
    >
      {{ label }}
    </div>
    <div
      class="PSSelect__select"
      :class="{
        '_show-border': isShow,
        '_hide-arrow': hideArrow,
        '_align-center': alignCenter,
        _disabled: disabled,
        _readonly: readonly,
      }"
    >
      <tippy
        interactive
        :arrow="false"
        :offset="[0, 0]"
        :max-width="width"
        :trigger="disabled || readonly ? '' : 'click'"
        :placement="'bottom'"
        :on-show="onShow"
        :on-hide="onHide"
        :theme="'dropdown'"
      >
        <div :class="{ PSSelect__value: !labelType }">
          <!-- Поиск -->
          <template v-if="search !== undefined && !props.readonly">
            <input
              autocomplete="off"
              class="PSSelect__input"
              type="text"
              :value="search"
              @input="$emit('update:search', $event.target.value)"
            >
          </template>

          <!-- Выбранное значение -->
          <template
            v-if="
              getLabelActive &&
                getLabelActive.label !== undefined &&
                getLabelActive.value !== undefined
            "
          >
            <template v-if="$slots.label">
              <slot name="label" />
            </template>

            <!-- Стиль тега -->
            <TagSimple
              v-else-if="labelType === 'TagSimple'"
              width="100%"
              :text="getLabelActive.label"
            />

            <!-- Стиль статуса  -->
            <StatusLabel
              v-else-if="labelType === 'StatusLabel'"
              width="100%"
              :status="getLabelActive.value"
            />

            <!-- Стиль Default -->
            <template v-else>
              <div class="PSSelect__value-row">
                <div
                  v-if="getLabelActive.color"
                  class="PSSelect__color-marker"
                  :style="'--bg-color:' + getLabelActive.color"
                />
                <div class="PSSelect__value-text">
                  {{ getLabelActive.label }}
                </div>
              </div>
            </template>
          </template>

          <template v-else>
            <span
              v-if="multiple"
              class="PSSelect__placeholder"
            >{{
              props.resetLabel
            }}</span>
            <TagSimple
              v-else-if="labelType === 'TagSimple'"
              width="100%"
              :text="placeholder"
            />
            <span
              v-else
              class="PSSelect__placeholder"
            >{{ placeholder }}</span>
          </template>

          <!-- Стрелочка -->
          <div
            v-if="!hideArrow && !readonly && !disabled"
            class="PSSelect__arrow"
          >
            <SvgIcon name="arr2-right" />
          </div>
        </div>

        <!-- Выпадающий список -->
        <template #content="{ hide }">
          <div
            class="PSSelect__dropdown"
            :style="{ '--widthDropdown': widthDropdown }"
          >
            <div
              ref="scroll"
              class="PSSelect__scroll"
            >
              <template v-if="multiple && multipleMap">
                <div
                  v-if="props.resetLabel"
                  ref="scroll2"
                  class="PSSelect__item"
                  :class="{ active: multipleMap.length === 0 }"
                  @click="reset"
                >
                  {{ props.resetLabel }}
                </div>
                <div
                  v-for="(option, optionIndex) in optionsSearch?.length
                    ? optionsSearch
                    : options"
                  :key="option.value"
                  ref="scroll2"
                  class="PSSelect__item"
                  :class="{ active: multipleMap[optionIndex] }"
                >
                  <PSCheck
                    v-model="multipleMap[optionIndex]"
                    :label="option.label"
                  />
                  <!-- @update:model-value="
                    :model-value="false"
                      (val) => toggleActive(optionIndex, val)
                    " -->
                </div>
              </template>
              <template v-else>
                <div
                  v-if="
                    props.resetLabel &&
                      modelValue !== undefined &&
                      modelValue !== ''
                  "
                  ref="scroll2"
                  class="PSSelect__item"
                  @click="reset"
                >
                  {{ props.resetLabel }}
                </div>

                <div
                  v-for="option in props.search ? optionsSearch : options"
                  :key="option.value"
                  ref="scroll2"
                  class="PSSelect__item"
                  :class="{ active: modelValue === option.value }"
                  @click="
                    setActive(option.value);
                    hide();
                  "
                >
                  <div
                    v-if="option.color"
                    class="PSSelect__color-marker"
                    :style="'--bg-color:' + option.color"
                  />
                  {{ option.label }}
                </div>
              </template>
            </div>
          </div>
        </template>
      </tippy>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTippy, } from 'vue-tippy'; // Подключаем библиотеку для тултипов
import StatusLabel from '../StatusLabel/StatusLabel.vue'; // Компонент для отображения статуса
import TagSimple from '../Tags/TagSimple.vue'; // Простой компонент тега
import PSCheck from './PSCheck.vue'; // Чекбокс компонент
import { PSSelectOptions, } from './PSSelect.type'; // Типы опций для выпадающего списка
import { ref, computed, watchEffect, nextTick, } from 'vue'; // Импортируем необходимые функции Vue

// Определение пропсов компонента
const props = withDefaults(
  defineProps<{
    modelValue: string | number | null | Array<string | number>; // Значение компонента (двунаправленное связывание)
    options?: PSSelectOptions; // Список доступных опций
    optionsSearch?: PSSelectOptions; // Опции для поиска
    alignCenter?: boolean; // Центрирование содержимого
    placeholder?: string; // Placeholder для выбора
    width?: string; // Ширина компонента
    height?: string; // Высота компонента
    hideArrow?: boolean; // Скрыть стрелку выпадающего списка
    disabled?: boolean; // Отключение компонента
    readonly?: boolean; // Только для чтения
    label?: string; // Надпись
    search?: string; // Текущее значение поиска
    isSearchLoading?: boolean; // Флаг загрузки при поиске
    dropdownAutoWidth?: boolean; // Автоматическая ширина выпадающего списка
    labelType?: 'TagSimple' | 'StatusLabel'; // Тип метки
    required?: boolean; // Обязательное поле
    multiple?: boolean; // Множественный выбор
    resetLabel?: string; // Текст кнопки сброса
    tooltip?: string; // Текст подсказки
  }>(),
  {
    placeholder: 'Выберете значение', // Значение по умолчанию для placeholder
    active: null, // Значение по умолчанию для активного элемента
  }
);

// Эмиттер событий
const emit = defineEmits<{
  (
    e: 'update:modelValue',
    value: string | number | Array<string | number> | undefined
  ): void; // Событие обновления значения
  (e: 'update:search', value: string | number): void; // Событие обновления строки поиска
}>();

// Реактивные переменные
const isShow = ref(false); // Флаг видимости выпадающего списка
const select = ref<HTMLDivElement>(); // Ссылка на корневой элемент компонента
const scroll = ref(); // Ссылка на скролл контейнера
const scroll2 = ref(); // Ссылка на список элементов в выпадающем списке
const widthDropdown = ref(''); // Ширина выпадающего списка

// Вычисляемое свойство для двунаправленной связи значения
const value = computed({
  get() {
    return props.modelValue; // Возвращаем текущее значение из пропсов
  },
  set(newValue) {
    if (newValue !== undefined) {
      emit('update:modelValue', newValue === null ? undefined : newValue); // Обновляем значение через эмиттер
    }
  },
});

// Вычисляемое свойство для получения активной метки
const getLabelActive = computed(() => {
  if (!props.options || !props.options.length) {
    return ''; // Если нет опций, возвращаем пустую строку
  }

  if (props.multiple) {
    let activeLabels = ''; // Строка для хранения выбранных значений

    // Собираем строку из выделенных значений
    if (typeof value.value === 'object') {
      value.value?.forEach((t) => {
        if (props.options) {
          const option = props.options.find((tt) => t === tt.value); // Находим соответствующую опцию
          if (activeLabels !== '') {
            activeLabels += ','; // Разделяем значения запятой
          }
          activeLabels += ' ' + (option?.label ?? ''); // Добавляем текст метки
        }
      });
    }

    return { label: activeLabels, value: value.value, }; // Возвращаем объект с меткой и значением
  } else {
    return props.options.find((t) => value.value === t.value) ?? {}; // Для одиночного выбора возвращаем первую найденную опцию
  }
});

// Метод для установки активного значения
const setActive = (newValue) => {
  console.log('Set new value:', newValue); // Логирование нового значения
  value.value = newValue; // Устанавливаем новое значение
};

// Реактивный массив для множественного выбора
const multipleMap = ref(props.options?.map((t) => false)); // Создаем массив флагов для каждой опции

// Наблюдатель за изменением множественного выбора
if (props.multiple) {
  watchEffect(() => {
    const values = multipleMap.value?.reduce((acc, item, index) => {
      if (item && props.options) {
        acc.push(props.options[index].value); // Добавляем значение выбранной опции
      }
      return acc;
    }, [] as Array<string | number>);

    emit('update:modelValue', values); // Обновляем значение через эмиттер
  });
}

// Наблюдатель за шириной выпадающего списка
watchEffect(() => {
  if (props.dropdownAutoWidth) {
    widthDropdown.value = 'auto'; // Если автоширина включена, устанавливаем "auto"
  } else if (select.value && select.value.clientWidth) {
    widthDropdown.value = `${select.value.clientWidth}px`; // Иначе используем ширину родительского элемента
  }
});

// Метод для показа выпадающего списка
const onShow = async (tippyWrap) => {
  if (!(props.disabled || props.readonly)) {
    isShow.value = true; // Показываем выпадающий список
    await nextTick(); // Ждем следующий цикл рендеринга

    // Автоподкрутка к активному элементу
    if (scroll2.value && scroll2.value[0]) {
      const h = scroll2.value[0].clientHeight; // Высота одного элемента
      let activeIndex = 0;

      scroll2.value.forEach((item, ndx) => {
        if (item.classList.contains('active')) {
          activeIndex = ndx; // Находим индекс активного элемента
        }
      });

      scroll.value.scrollTop = activeIndex * h; // Прокручиваем до активного элемента
    }
  }
};

// Метод для скрытия выпадающего списка
const onHide = (instance) => {
  instance.value?.hide(); // Скрываем тултип или выпадающий список
  isShow.value = false; // Скрываем выпадающий список
};

// Метод для сброса значения
const reset = () => {
  if (props.multiple) {
    emit('update:modelValue', []); // Сбрасываем значение для множественного выбора
    if (multipleMap.value) {
      multipleMap.value = props.options?.map((t) => false); // Сбрасываем флаги выбора
    }
  } else {
    emit('update:modelValue', undefined); // Сбрасываем значение для одиночного выбора
  }
};

// Инициализация тултипа
if (props.tooltip) {
  useTippy(select, {
    content: props.tooltip, // Текст подсказки
  });
}
</script>

<style lang="scss" scoped>
.PSSelect {
  $s: &;

  position: relative;
  width: var(--width);

  &__label {
    color: #464646;
    font-weight: 300;
    margin-bottom: 10px;
    padding-left: 10px;
    line-height: 1.25;

    &--required {
      &::before {
        position: absolute;
        content: '*';
        left: 0px;
        top: 0;
        color: $red;
      }
    }
  }
  &__color-marker {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: var(--bg-color);
  }
  &__select {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: var(--width);
    height: var(--height);

    &._disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    &._readonly {
    }
    &._hide-arrow {
      #{$s}__value {
        padding-right: 15px;
      }
    }
    &._show-border {
      #{$s}__value {
        border-color: $blue;
      }
    }
    &._align-center {
      text-align: center;
    }
  }
  &__input {
    border: none !important;
    outline: none !important;
    background-color: $light-max;
    opacity: 0;
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    padding: 10px - 2px 16px - 2px;
    font-size: 20px;

    &:focus {
      opacity: 1;
    }
  }
  &__value {
    font-size: 20px;
    line-height: 1;
    background-color: $light-max;
    min-height: 40px;
    height: var(--height);
    padding: 9px 15px;
    padding-right: 34px;
    border: 1px solid transparent;
    border-radius: 6px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &-row {
      display: flex;
      align-items: center;
    }

    &-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__placeholder {
    color: #464646;
    font-weight: 300;
  }
  &__dropdown {
    padding: 10px 0;
    max-width: 100%;
    width: var(--widthDropdown);
  }
  &__scroll {
    max-height: 200px;
    overflow: auto;

    padding: 0 16px;

    @include scroll;
  }
  &__arrow {
    position: absolute;
    right: 16px;
    // top: 8px;
    top: 50%;

    transform: translate(0, -50%) rotate(90deg);
    :deep(svg) {
      fill: $blue;
      width: 6px;
      height: 11px;
    }
  }
  &__item {
    font-size: 20px;
    cursor: pointer;
    position: relative;
    z-index: 1;

    @include hover {
      color: $blue;
    }
    &.active {
      color: white;
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: $blue;
        left: -16px;
        right: -16px;
        top: 0;
        bottom: 0;
      }
    }
  }
}
</style>

<style lang="scss">
.PSSelect {
  .status-label {
    padding-right: 34px;
  }
  .tag {
    padding-right: 34px;
  }
}
</style>
